import styled from "styled-components";

export const TopHeader = styled.header`
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 5rem;
  background-color: transparent;
  color: var(--textColor);

  div {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 1024px) {
    .responsive_nav {
      transform: none;
    }
  }
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  a {
    margin: 0 1rem;
    color: var(--textColor);
    text-decoration: none;
    .contato-texto {
      border: 2px solid #a582d2;
      padding: 0 0.5rem;
      border-radius: 0.5rem;
    }
    h3 {
      font-weight: normal;
      border-bottom: 2px solid #a582d2;
    }
  }

  a:hover {
    color: var(--mainColorLight);
  }
  @media only screen and (max-width: 1024px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    transform: translateY(-100vh);

    .nav-close-btn {
      position: absolute;
      top: 2rem;
      right: 2rem;
      color: #fff;
    }
    a {
      h3 {
        color: var(--textColor);
      }
    }
  }
`;

export const NavButton = styled.button`
  display: none;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-size: 2rem;
  @media only screen and (max-width: 1024px) {
    display: block;
    visibility: visible;
    opacity: 1;
  }
`;

export const ImagemHeader = styled.img`
  width: 80%;
`;

export const BotaoSair = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  h3 {
    font-weight: normal;
    border-bottom: 2px solid #a582d2;
  }
`;

import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCtF-D78TGgrmRLxt4c80b4-0fP3KnnYck",
  authDomain: "login-finance-fengari.firebaseapp.com",
  projectId: "login-finance-fengari",
  storageBucket: "login-finance-fengari.appspot.com",
  messagingSenderId: "386802692932",
  appId: "1:386802692932:web:093c7fef033819036b9d11",
  measurementId: "G-G6RSYGYNRS",
};

const app = firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

export { firebase, auth, app}
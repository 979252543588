import React, { useState } from "react";
import { Tr, Td } from "./style.js";
import { ModalDes } from "../Modal";

import {
  FaRegArrowAltCircleUp,
  FaRegArrowAltCircleDown,
  FaTrash,
} from "react-icons/fa";

const TableLine = (props) => {
  const arrowIcon = () => {
    if (props.item.type == "entrada") {
      return <FaRegArrowAltCircleUp color="green" />;
    } else {
      return <FaRegArrowAltCircleDown color="red" />;
    }
  };
  const dataAtualFormatada = (data) => {
    var data = new Date(),
      dia = data.getDate().toString(),
      diaF = dia.length == 1 ? "0" + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = mes.length == 1 ? "0" + mes : mes,
      anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };
  const consultaTipo = (tipoSemFormar) => {
    if (tipoSemFormar == "entra") {
      return "entrada";
    } else {
      return "Saída";
    }
  };
  const alerta = () => {
    const mensagemAlerta = `
    Descrição: ${props.item.description}\n
    Valor: ${props.item.amount}\n
    Data: ${dataAtualFormatada(props.item.data)}\n
    Tipo: ${props.item.type}`;
    alert(mensagemAlerta);
  };

  return (
    <Tr>
      <Td onClick={alerta}>{props.item.description}</Td>
      <Td onClick={alerta}>{props.item.amount}</Td>
      {/* <Td alignCenter onClick={alerta}>
        {arrowIcon()}
      </Td> */}
      <Td alignCenter>
        <FaTrash onClick={() => props.delete(props.item.id)} />
      </Td>
    </Tr>
  );
};

export default TableLine;

import React, { useRef } from "react";
import {
  Navigation,
  ImagemHeader,
  TopHeader,
  NavButton,
  BotaoSair,
} from "./style.js";

import logo_fg from "../../img/logo-fengari.png";
import { auth } from "../../service/firebase";
import useAuth from "../../hooks/useAuth";

import { HashLink as Link } from "react-router-hash-link";

import { FaBars, FaTimes } from "react-icons/fa";

export const Header = () => {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  const { user, setUser } = useAuth();

  const handleClickButtonLogout = async () => {
    const result = await auth.signOut();
    setUser(result);
  };

  return (
    <TopHeader>
      <Link smooth to="/">
        <ImagemHeader src={logo_fg} />
      </Link>
      <Navigation ref={navRef}>
        <Link to="/" onClick={showNavbar}>
          <h3>Home</h3>
        </Link>
        <Link to="/entradas" onClick={showNavbar}>
          <h3>Entradas</h3>
        </Link>
        <Link to="/saidas" onClick={showNavbar}>
          <h3>Saídas</h3>
        </Link>
        <BotaoSair onClick={handleClickButtonLogout}>
          <h3>Sair</h3>
        </BotaoSair>
        <NavButton className=" nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </NavButton>
      </Navigation>
      <NavButton onClick={showNavbar}>
        <FaBars />
      </NavButton>
    </TopHeader>
  );
};

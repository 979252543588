import "./App.css";
import { auth } from "./service/firebase";
import React, { useState, useEffect } from "react";
import useAuth from "./hooks/useAuth";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RotaPrivada } from "./RotaPrivada";
import { PagInicial } from "./Pages/PagInicial";
import { Entrada } from "./Pages/Entrada";
import { Saida } from "./Pages/Saida";
import { Header } from "./Components/Header";
import { Login } from "./Pages/Login";
import axios from "axios";

function App() {
  const [atualiza, setAtualiza] = useState(false);
  const { user, setUser } = useAuth();
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState("");
  const [isExpense, setIsExpense] = useState(false);
  const [date, setDate] = useState("");

  const [data, setData] = useState([]);
  const [income, setIncome] = useState(0);
  const [expense, setExpense] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const { uid } = user;

        setUser({
          id: uid,
        });
      }
    });
  }, []);

  const handleClickButtonLogin = async () => {
    const result = await auth
      .signInWithEmailAndPassword(loginEmail, loginPassword)
      .catch((error) => {
        if (
          error.message ==
          "There is no user record corresponding to this identifier. The user may have been deleted."
        ) {
          alert("usuario não identificado");
        } else if (
          error.message ==
          "The password is invalid or the user does not have a password."
        ) {
          alert("Senha incorreta");
        }
      });

    if (result.user) {
      const { uid } = result.user;

      setUser({
        id: uid,
      });
    } else {
      alert("Usuário ou senha incorreta");
    }
  };

  useEffect(() => {
    try {
      axios
        .get("https://finan-fengari.herokuapp.com/dados")
        .then((response) => {
          setData(response.data);
        });
    } catch (error) {
      console.log(error);
    }

    setAtualiza(false);
  }, [atualiza]);

  useEffect(() => {
    const amountExpense = data
      .filter((item) => item.type == "saida")
      .map((transaction) => Number(transaction.amount));

    const amountIncome = data
      .filter((item) => item.type == "entrada")
      .map((transaction) => Number(transaction.amount));

    const expense = amountExpense.reduce((acc, cur) => acc + cur, 0).toFixed(2);
    const income = amountIncome.reduce((acc, cur) => acc + cur, 0).toFixed(2);

    const total = Math.abs(income - expense).toFixed(2);

    setIncome(`R$ ${income}`);
    setExpense(`R$ ${expense}`);
    setTotal(`${Number(income) < Number(expense) ? "-" : ""}R$ ${total}`);
  }, [data]);

  const generateID = () => Math.round(Math.random() * 1000);
  const handleAdd = () => {
    try {
      axios.post("https://finan-fengari.herokuapp.com/dados", {
        desc: desc,
        amount: parseFloat(amount),
        type: !isExpense,
        date: date,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    if (!desc || !amount) {
      alert("Informe a descrição e o valor!");
      return;
    } else if (amount < 1) {
      alert("O valor tem que ser positivo!");
      return;
    }

    const transaction = {
      id: generateID(),
      desc: desc,
      amount: amount,
      expense: isExpense,
      date: date,
    };

    handleAdd(transaction);
    setAtualiza(true);

    setDesc("");
    setAmount("");

    alert("Valor inserido com sucesso");
  };

  const onDelete = (ID) => {
    try {
      axios.delete(`https://finan-fengari.herokuapp.com/dados/${ID}`);
    } catch (error) {
      console.log(error);
    }
    setAtualiza(true);
    alert("Valor excluído com sucesso!!");
  };

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/login"
          element={
            <Login
              setLoginEmail={setLoginEmail}
              setLoginPassword={setLoginPassword}
              handleClickButtonLogin={handleClickButtonLogin}
            />
          }
        />
        <Route
          exact
          path="/"
          element={
            <RotaPrivada
              exact
              path="/"
              element={
                <PagInicial
                  income={income}
                  expense={expense}
                  total={total}
                  Header={<Header />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/entradas"
          element={
            <RotaPrivada
              exact
              path="/"
              element={
                <Entrada
                  atualiza={setAtualiza}
                  income={income}
                  Header={<Header />}
                />
              }
            />
          }
        />
        <Route
          exact
          path="/saidas"
          element={
            <RotaPrivada
              exact
              path="/"
              element={
                <Saida
                  atualiza={setAtualiza}
                  expense={expense}
                  Header={<Header />}
                />
              }
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import {
  ContainerGeralForm,
  ContainerForm,
  InputContentForm,
  LabelForm,
  ContainerEntradas,
  InputForm,
  ButtonForm,
  CardResume,
  TextCardResume,
  ContainerType,
  TitleEntrada
} from "./style.js";
import axios from "axios";
import Resume from "../../Components/Resume";
import TableConst from "../../Components/Table";

export const Entrada = (props) => {
  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [atualiza, setAtualiza] = useState(false);

  const [data, setData] = useState([]);
  const [income, setIncome] = useState(0);
  const [expense, setExpense] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    try {
      axios
        .get(`https://finan-fengari.herokuapp.com/dados/entrada`)
        .then((response) => {
          setData(response.data);
        });
    } catch (error) {
      console.log(error);
    }

    setAtualiza(false);
  }, [atualiza]);

  useEffect(() => {
    const amountIncome = data
      .filter((item) => item.type == "entrada")
      .map((transaction) => Number(transaction.amount));

    const income = amountIncome.reduce((acc, cur) => acc + cur, 0).toFixed(2);
    setIncome(`R$ ${income}`);
  }, [data]);

  const generateID = () => Math.round(Math.random() * 1000);
  const handleAdd = () => {
    try {
      axios.post("https://finan-fengari.herokuapp.com/dados", {
        desc: desc,
        amount: parseFloat(amount),
        type: "entrada",
        date: date,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    if (!desc || !amount) {
      alert("Informe a descrição e o valor!");
      return;
    } else if (amount < 1) {
      alert("O valor tem que ser positivo!");
      return;
    }

    const transaction = {
      id: generateID(),
      desc: desc,
      amount: amount,
      expense: "entrada",
      date: date,
    };

    handleAdd(transaction);
    setAtualiza(true);

    setDesc("");
    setAmount("");

    alert("Valor inserido com sucesso");
  };

  const onDelete = (ID) => {
    try {
      axios.delete(`https://finan-fengari.herokuapp.com/dados/${ID}`);
    } catch (error) {
      console.log(error);
    }
    setAtualiza(true);
    alert("Valor excluído com sucesso!!");
  };

  return (
    <ContainerGeralForm>
      {props.Header}
      <TitleEntrada>Entradas</TitleEntrada>
      <ContainerEntradas>
        <ContainerType>
          <CardResume>
            <TextCardResume>{income}</TextCardResume>
          </CardResume>

          <ContainerForm>
            <InputContentForm>
              <LabelForm>Descrição</LabelForm>
              <InputForm
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </InputContentForm>
            <InputContentForm>
              <LabelForm>Valor</LabelForm>
              <InputForm
                value={amount}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
              />
            </InputContentForm>
            <InputContentForm>
              <LabelForm>Data</LabelForm>
              <InputForm
                value={date}
                type="date"
                onChange={(e) => setDate(e.target.value)}
              />
            </InputContentForm>
            <ButtonForm onClick={handleSave}>Adicionar</ButtonForm>
          </ContainerForm>
        </ContainerType>
        <TableConst itens={data} delete={onDelete} />
      </ContainerEntradas>
    </ContainerGeralForm>
  );
};

// export default EntradaSaidas;

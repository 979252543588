import styled from "styled-components";

export const Tr = styled.tr``;

export const Td = styled.td`
  padding-top: 15px;
  text-align: ${(props) => (props.alignCenter ? "center" : "start")};
  /* word-break: break-all; */
  /* display: fixed; */
  overflow: hidden;
  text-overflow: ellipsis;

  svg {
    width: 18px;
    height: 18px;
  }
  @media only screen and (max-width: 1024px) {
    text-align: top;
  }
`;

import styled from "styled-components";

export const PaginaInicial = styled.div`
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(303.34deg, #2a0845 54.02%, #6441a5 99.2%);

  @media (max-width: 1024px) {
    padding-bottom: 2rem;
  }
`;
export const TitleEntrada = styled.h1`
  margin: 1rem;
  color: var(--textColor);
`;
export const ContainerPaginaInicial = styled.div`
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  /* padding: 1rem; */


  @media only screen and (max-width: 1024px) {
    margin-top: 4rem;
    justify-content: center;

  }
`;
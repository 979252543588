import styled from "styled-components";

export const PaginaLogin = styled.div`
  min-height: 100vh;
  background: linear-gradient(303.34deg, #2a0845 54.02%, #6441a5 99.2%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerLogin = styled.div`
  background: #ededed;
  padding: 4rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem ;
  @media only screen and (max-width: 1024px) {
    padding: 2rem;
    width: 70%;

  }
`;
export const TituloPaginaLogin = styled.h1`
  color: #7a7a7a;
  margin-bottom: 2rem ;
`;

export const DivInput = styled.div`
  width: 100% ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  label{
    right: 0 ;
    width: 90% ;
    color: #7a7a7a;
  }
`

export const InputLogin = styled.input`
padding-left: 0.5rem;
  height: 2.5rem;
  width: 90%;
  margin-bottom: 2rem ;
  background-color: transparent ;
  border: none;
  border-bottom: 1px solid;
`;

export const BotaoLogin = styled.button`
  cursor: pointer;
  width: 90%;
  height: 2.5rem;
  border: none;
  color: #fff;
  background-color: #452372 ;
  border-radius: .5rem ;

`;

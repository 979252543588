import React, { useState } from "react";
import { Table, Thead, Tbody, Tr, Th } from "./style.js";
import TableItem from "../TableLine";

const TableConst = (props) => {

  return (
    <Table>
      <Thead>
        <Tr>
          <Th width={40}>Descrição</Th>
          <Th width={40}>Valor</Th>
          {/* <Th width={10} alignCenter>
            Tipo
          </Th> */}
          <Th width={10}></Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.itens?.map((item, index) => (
          <TableItem
            key={index}
            item={item}
            delete={props.delete}
            modal={props.modal}
          />
        ))}
      </Tbody>
    </Table>
  );
};

export default TableConst;

import styled from "styled-components";

export const ContainerGeralForm = styled.div`
  min-height: 100vh;
  
  width: 100%;
  background: linear-gradient(303.34deg, #2a0845 54.02%, #6441a5 99.2%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ContainerEntradas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
`;

export const ContainerType = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const TitleEntrada = styled.h1`
  margin: 1rem;
  color: var(--textColor);
`;
export const CardResume = styled.div`
  background-color: #fff;
  padding: 4rem 2rem;
  border-radius: 1rem;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: 1024px) {
    width: 82%;
    min-height: 1rem;
    height: 1rem;
  }
`;
export const TextCardResume = styled.h2`
  font-size: 1.7rem;
  color: #373738;
`;

export const ContainerForm = styled.div`
  width: 40%;
  background-color: var(--mainColor);
  color: #fff;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 2rem;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const InputContentForm = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 15%; */
  margin: 1rem;
  width: 90%;

  @media only screen and (max-width: 1024px) {
    width: 90%;
  }
`;

export const LabelForm = styled.label``;

export const InputForm = styled.input`
  outline: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 15px;
  border: 1px solid #ccc;
`;

export const ButtonForm = styled.button`
  height: 3rem;
  width: 90%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: teal;
  margin: 2rem;

  @media only screen and (max-width: 1024px) {
    width: 90%;
    margin-bottom: 2rem;
  }
`;

import styled from "styled-components";

export const ContainerResume = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: row;
  width: 100%;
  min-height: 10rem;
  gap: 2rem;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    width: 90%;
  }
`;

export const CardResume = styled.div`
  background-color: #fff;
  padding: 4rem 2rem;
  border-radius: 1rem;
  width: 100%;
  height: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: 1024px) {
    width: 90%;
    min-height: 1rem;
    height: 1rem;
  }
`;
export const TextCardResume = styled.h2`
  font-size: 1.7rem;
  color: #373738;
`;

export const TituloCardResume = styled.h3`
  color: #5e5f61;
`;

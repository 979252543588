import React, { useState, useEffect } from "react";
import {
  PaginaInicial,
  ContainerPaginaInicial,
  TitleEntrada,
} from "./style.js";
import Form from "../../Components/Form";
import Resume from "../../Components/Resume";
import axios from "axios";


export const PagInicial = (props) => {
  const [atualiza, setAtualiza] = useState(false);
  const [data, setData] = useState([]);
  const [income, setIncome] = useState(0);
  const [expense, setExpense] = useState(0);
  const [total, setTotal] = useState(0);
  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState("");
  const [isExpense, setIsExpense] = useState(false);
  const [date, setDate] = useState("");


  useEffect(() => {
    try {
      axios
        .get("https://finan-fengari.herokuapp.com/dados")
        .then((response) => {
          setData(response.data);
        });
    } catch (error) {
      console.log(error);
    }

    setAtualiza(false);
  }, [atualiza]);

  useEffect(() => {
    const amountExpense = data
      .filter((item) => item.type == "saida")
      .map((transaction) => Number(transaction.amount));

    const amountIncome = data
      .filter((item) => item.type == "entrada")
      .map((transaction) => Number(transaction.amount));

    const expense = amountExpense.reduce((acc, cur) => acc + cur, 0).toFixed(2);
    const income = amountIncome.reduce((acc, cur) => acc + cur, 0).toFixed(2);

    const total = Math.abs(income - expense).toFixed(2);

    setIncome(`R$ ${income}`);
    setExpense(`R$ ${expense}`);
    setTotal(`${Number(income) < Number(expense) ? "-" : ""}R$ ${total}`);
  }, [data]);

  const generateID = () => Math.round(Math.random() * 1000);
  const handleAdd = () => {
    try {
      axios.post("https://finan-fengari.herokuapp.com/dados", {
        desc: desc,
        amount: parseFloat(amount),
        type: !isExpense,
        date: date,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = () => {
    if (!desc || !amount) {
      alert("Informe a descrição e o valor!");
      return;
    } else if (amount < 1) {
      alert("O valor tem que ser positivo!");
      return;
    }

    const transaction = {
      id: generateID(),
      desc: desc,
      amount: amount,
      expense: isExpense,
      date: date,
    };

    handleAdd(transaction);
    setAtualiza(true);

    setDesc("");
    setAmount("");

    alert("Valor inserido com sucesso");
  };

  const onDelete = (ID) => {
    try {
      axios.delete(`https://finan-fengari.herokuapp.com/dados/${ID}`);
    } catch (error) {
      console.log(error);
    }
    setAtualiza(true);
    alert("Valor excluído com sucesso!!");
  };


  return (
    <>
      <PaginaInicial id="home">
        {props.Header}
        <ContainerPaginaInicial>
          <TitleEntrada>Página inicial</TitleEntrada>

          <Resume
            income={income}
            expense={expense}
            total={total}
          />
        </ContainerPaginaInicial>
      </PaginaInicial>
    </>
  );
};

import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

import { Route, Navigate, Routes } from "react-router-dom";

import {
  ContainerLogin,
  TituloPaginaLogin,
  InputLogin,
  BotaoLogin,
  PaginaLogin,
  DivInput
} from "./style.js";

export const Login = (props) => {
  const { user } = useContext(AuthContext);

  return (
    <Routes>
      {!!user ? (
        <Route exact path="/" element={<Navigate to="/" />} />
      ) : (
        <Route
          exact
          path="/"
          element={
            <PaginaLogin>
              <ContainerLogin>
                <TituloPaginaLogin>Login</TituloPaginaLogin>
                <DivInput>
                  <label>E-mail</label>
                  <InputLogin
                    placeholder="Digite seu email"
                    onChange={(event) => {
                      props.setLoginEmail(event.target.value);
                    }}
                  />
                </DivInput>
                <DivInput>
                  <label>Senha</label>
                  <InputLogin
                    placeholder="Digite sua senha"
                    onChange={(event) => {
                      props.setLoginPassword(event.target.value);
                    }}
                    type="password"
                  />
                </DivInput>
                <BotaoLogin onClick={props.handleClickButtonLogin}>
                  Entrar
                </BotaoLogin>
              </ContainerLogin>
            </PaginaLogin>
          }
        />
      )}
    </Routes>
  );
};

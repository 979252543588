import React, { useContext } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';

export const RotaPrivada = (props) => {

  const { user } = useContext(AuthContext);

  return (
    <Routes>
      {
        (!!user) ? <Route
        exact
        path={props.path}
        element={
          props.element
        }
      /> : <Route
      exact
      path="/"
      element={
        <Navigate to="/login"/>
      }
    />
      }
    </Routes>

  );
};
import React from "react";
import {
  ContainerResume,
  CardResume,
  TextCardResume,
  TituloCardResume,
} from "./style.js";

const Resume = (props) => {
  return (
    <ContainerResume>
      <CardResume>
        <TituloCardResume>Entradas</TituloCardResume>

        <TextCardResume>{props.income}</TextCardResume>
      </CardResume>
      <CardResume>
        <TituloCardResume>Saídas</TituloCardResume>
        <TextCardResume>{props.expense}</TextCardResume>
      </CardResume>
      <CardResume>
        <TituloCardResume>Total</TituloCardResume>

        <TextCardResume>{props.total}</TextCardResume>
      </CardResume>
    </ContainerResume>
  );
};

export default Resume;

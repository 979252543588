import styled from "styled-components";
export const ContainerGeralForm = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 1024px) {
    width: 90%;
  }
`;

export const ContainerForm = styled.div`
  width: 100%;
  background-color: var(--mainColor);
  color: #fff;
  box-shadow: 0px 0px 5px #ccc;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2rem;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const InputContentForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 15%;
  margin: 1rem;
  @media only screen and (max-width: 1024px) {
    width: 90%;

  }
`;

export const LabelForm = styled.label``;

export const InputForm = styled.input`
  outline: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 15px;
  border: 1px solid #ccc;
`;

export const RadioGroupForm = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 20%;

  input {
    margin-left: 20px;
    margin-right: 5px;
    accent-color: black;
    margin-top: 0;
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const ButtonForm = styled.button`
  height: 3rem;
  width: 10%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: teal;
  @media only screen and (max-width: 1024px) {
    width: 90%;
    margin-bottom: 2rem;

  }
`;
